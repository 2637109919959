import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Homepage } from './Pages/Homepage/Homepage';
import { Navbar } from './Components/Navbar/Navbar';
import { Navbar2 } from './Components/Navbar/Navbar2';
import { useLocation } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Blogpage } from './Pages/Blogpage/Blogpage';
import { Footer } from './Components/Footer/Footer';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/Blogpage' element={<Blogpage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

function Header() {
  const location = useLocation();
  return location.pathname === "/Blogpage" ? <Navbar2 /> : <Navbar />;
}

export default App;
