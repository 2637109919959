import React from 'react';
import { Link } from 'react-router-dom';
import HsyLogo from '../../Assets/Images/hsky-logo.png';

export const Navbar = () => (
    <div>
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <div className="logoDiv">
                            <Link to="/"><img src={HsyLogo} alt="logo" /></Link>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="navigationBar">
                            <ul>
                                <li><a href="/#About">About</a></li>
                                <li><a href="/#Services">Services</a></li>
                                <li><a href="/#contact">Contact</a></li>
                                <li><Link to="Blogpage">Blogs</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
);

export default Navbar;
