import React from 'react'
import './Blogpage.css'
import blogimg from '../../Assets/Images/nature1.png'
import { Link } from 'react-router-dom';

export const Blogpage = () => {
    return (
        
        <div>
            <section className="BlogBannerDivMain">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="BlogBannerDiv">
                                <p>Blogs</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10" style={{margin: 'auto'}}>
                            <div className="blogImg">
                                <img src={blogimg} alt="BlogImg" />
                            </div>

                            <div className="blogDetails">
                                <p>Written By : Muninder Singh</p>
                                <p>05-05-2024</p>

                                <h2>The Hsky - Hara Sky</h2>
                            </div>

                            <div className="blogBody">
                                <p>
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis.

                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis.
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis.
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis.
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci sapiente illo quisquam natus tenetur quidem ut nulla? Vero cumque deserunt aliquam tempora, perferendis quam natus odio, id animi similique corporis.
                                </p>
                            </div>


                            <div className="BlogComments">

                            </div>

                        </div>
                        <div className="col-md-4" style={{ display:'none' }}>
                            <div className="lineBorder">
                            <div className="blogsRelatdMain" >
                            <div className="relatedBlogsHeading">
                                <h2>Related Blogs</h2>
                            </div>
                            <div className="relatedBlogs">
                                <ul>
                                    <li>
                                        <Link to="#">Hsky-Hara Sky</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Hsky-Hara Sky</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Hsky-Hara Sky</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Hsky-Hara Sky</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Hsky-Hara Sky</Link>
                                    </li>
                                </ul>
                            </div>
                            </div>
                        </div></div>
                    </div>
                </div>
            </section>
        </div>
    )
}

