import React from 'react'
import { Banner } from '../../Components/Banner/Banner'
import aboutimg from '../../Assets/Images/about-5.jpg'
import natureImg from '../../Assets/Images/nature1.png'
import globalInsightImg from '../../Assets/Images/GlobalInsights.png'
import EmpoweringChange from '../../Assets/Images/EmpoweringChange.jpg'
import JointheMovement from '../../Assets/Images/JointheMovement.png'
import StartYourJourney from '../../Assets/Images/StartYourJourney.png'
 
export const Homepage = () => {
    return (
        <>
            <Banner />
            <section className="custmargin" id="About">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="introBox">

                                <h3>
                                    <span style={{color: '#00bf63'}}>Who </span> are we
                                </h3>
                                <p>
                                    In the grand tapestry of existence, our Earth is a precious thread, interwoven with the
                                    delicate balance of life. Yet, in recent times, this balance has been threatened by our own
                                    actions. The concept of sustainability emerges as a beacon of hope, illuminating a path
                                    towards harmony between humanity and the planet we call home.
                                </p>
                                <p>
                                    Living sustainably requires a shift in mindset, a departure from the throwaway culture that
                                    has become all too prevalent in modern society. It calls for mindfulness in consumption,
                                    valuing quality over quantity, and embracing practices such as recycling, upcycling, and
                                    composting. It encourages innovation and the adoption of renewable energy sources, moving
                                    away from fossil fuels towards a future powered by the sun, wind, and waves.
                                </p>
                                <p>
                                    Education plays a pivotal role in fostering a culture of sustainability, empowering
                                    individuals with the knowledge and skills needed to make informed choices and effect
                                    positive change. From classNamerooms to boardrooms, from communities to corporations, there is a
                                    growing recognition of the importance of integrating sustainability into every aspect of our
                                    lives.

                                </p>
                                <p>
                                    As we stand at the crossroads of history, the choices we make today will shape the world of
                                    tomorrow. The path to sustainability is not always easy, fraught with challenges and
                                    obstacles, but it is a journey worth undertaking. For in nurturing the health of our planet,
                                    we secure a brighter future for all who call it home.
                                </p>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="abtImg">
                                <img src={aboutimg} alt="about-5" />
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-md-3 col-6">
                            <div className="uspBox">
                                <div>
                                <i class="fa-solid fa-spa"></i>
                                </div>
                                <div className="uspDetail">
                                    <p>Mindful</p>
                                    <span>Quality over quantity</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="uspBox">
                                <div>
                                <i class="fa-solid fa-retweet"></i>
                                </div>
                                <div className="uspDetail">
                                    <p>Renewable</p>
                                    <span>Sun, wind, waves</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="uspBox">
                                <div>
                                <i class="fa-solid fa-graduation-cap"></i>
                                </div>
                                <div className="uspDetail">
                                    <p>Education</p>
                                    <span>For positive change</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="uspBox">
                                <div>
                                <i class="fa-solid fa-plus"></i>
                                </div>
                                <div className="uspDetail">
                                    <p>Integrating </p>
                                    <span>Recognizing</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="Services">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="servDiv">
                                <h2>Explore <span style={{ color: '#00bf63' }}>Asia's Sustainable</span>  Awakening</h2>
                                <p>
                                    Asia, home to diverse cultures and landscapes, is witnessing a remarkable transformation
                                    towards renewable energy and sustainability. From the bustling streets of Tokyo to the
                                    serene villages of rural India, communities are embracing innovative solutions to combat
                                    climate change and preserve our planet for future generations. Discover the latest trends,
                                    groundbreaking projects, and success stories shaping Asia's sustainable landscape.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="imgDiv">
                                <img src={natureImg} alt="nature1.jpg" />
                            </div>
                        </div>
                    </div>


                    <div className="row  mt-5" id="lgScreen">
                        <div className="col-md-6">
                            <div className="imgDiv">
                            <img src={globalInsightImg} alt="globalInsightImg" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="servDiv">
                                <h2>Global Insights for a  <span style={{ color: "#00bf63" }}>Greener World</span></h2>
                                <p>
                                    But our mission extends far beyond Asia's borders. GreenHorizon is your passport to global sustainability, where we highlight pioneering efforts, breakthrough technologies, and impactful policies from every corner of the Earth. From the wind farms of Scandinavia to the solar fields of Africa, we celebrate the triumphs and challenges of building a cleaner, more sustainable world.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row  mt-5"  id="smScreen">
                    <div className="col-md-6">
                            <div className="servDiv">
                                <h2>Global Insights for a  <span style={{ color: "#00bf63" }}>Greener World</span></h2>
                                <p>
                                    But our mission extends far beyond Asia's borders. GreenHorizon is your passport to global sustainability, where we highlight pioneering efforts, breakthrough technologies, and impactful policies from every corner of the Earth. From the wind farms of Scandinavia to the solar fields of Africa, we celebrate the triumphs and challenges of building a cleaner, more sustainable world.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="imgDiv">
                            <img src={globalInsightImg} alt="globalInsightImg" />
                            </div>
                        </div>
                       
                    </div>

                    <div className="row  mt-5">
                        <div className="col-md-6">
                            <div className="servDiv">
                                <h2><span style={{ color: "#00bf63" }}>Empowering Change,</span> One Story at a Time   </h2>
                                <p>
                                    At the heart of GreenHorizon are the stories of individuals, communities, and organizations driving positive change. Whether it's a small-scale initiative to reduce plastic waste or a multinational corporation committing to carbon neutrality, every action counts. Through our platform, we amplify these voices, inspiring others to join the movement towards a greener tomorrow.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="imgDiv">
                                <img src={EmpoweringChange} alt="EmpoweringChange" />
                            </div>
                        </div>
                    </div>



                </div>
            </section>

            <section className="joinDiv" id="lgScreen">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="imgDiv">
                                <img src={JointheMovement} alt="greener-world.png" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="servDiv">
                                <h2>Join the   <span style={{ color: "#00bf63" }}>Movement</span></h2>
                                <p>
                                    Whether you're an environmental enthusiast, a sustainability professional, or simply curious about the future of our planet, GreenHorizon embraces you with deep green arms. Explore our curated content, connect with like-minded individuals, and take action in your own community. Together, we can create a world where nature thrives, and every living being flourishes.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="joinDiv" id="smScreen">
                <div className="container">
                <div className="col-md-6">
                            <div className="servDiv">
                                <h2>Join the   <span style={{ color: "#00bf63" }}>Movement</span></h2>
                                <p>
                                    Whether you're an environmental enthusiast, a sustainability professional, or simply curious about the future of our planet, GreenHorizon embraces you with deep green arms. Explore our curated content, connect with like-minded individuals, and take action in your own community. Together, we can create a world where nature thrives, and every living being flourishes.
                                </p>
                            </div>
                        </div>
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="imgDiv">
                                <img src={JointheMovement} alt="greener-world.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section>
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="servDiv">
                                <h2><span style={{ color: "#00bf63" }}>Start Your Journey</span>  with us:   </h2>
                                <p>
                                    Join us at Hara sky and be part of the global movement towards a sustainable future. So let us embark on this journey together, embracing the principles of sustainability in every aspect of our lives, and together, let us strive to make the globe sustain.Together, let's turn the tide and chart a course towards a greener, more prosperous tomorrow. The journey starts here.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="imgDiv imgDivv pt-5">
                                <img src={StartYourJourney} alt="StartYourJourney" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section className="sermainBG" id="Blogs">
                <div className="container">

                    <div className="row custmargin">
                        <div className="div col-md-12">
                            <div className="headingTextBox" style={{ color: "#fff" }} >
                                <span>Updates News</span>
                                <h3>
                                    Latest Posts
                                </h3>
                                <p>Proactively revolutionize granular customer service after pandemic internal or "organic"
                                    sources istinctively impact proactive human</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="blogMain">
                                <div className="blogImg">
                                    <img src="img/b-1-1.jpg" alt="b-1-1.jpg" />
                                </div>
                                <div className="blogtext">
                                    <span>01 Aug, 2023</span>
                                    <p>From its medieval origins to the digital era everything there</p>
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="blogMain">
                                <div className="blogImg">
                                    <img src="img/b-1-1.jpg" alt="b-1-1.jpg" />
                                </div>
                                <div className="blogtext">
                                    <span>01 Aug, 2023</span>
                                    <p>From its medieval origins to the digital era everything there</p>
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="blogMain">
                                <div className="blogImg">
                                    <img src="img/b-1-1.jpg" alt="b-1-1.jpg" />
                                </div>
                                <div className="blogtext">
                                    <span>01 Aug, 2023</span>
                                    <p>From its medieval origins to the digital era everything there</p>
                                    <a href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="div col-md-12">
                            <div className="headingTextBox mt-5">
                                <button className="customBtn2">See More</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container" id="contact">
                <div className="section-contact">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10 col-xl-8">
                            <div className="headingTextBox">
                                <h3>
                                    Get In Touch With Us
                                </h3>
                                <p>Ready to turn over a new leaf? Reach out and let's embark on a journey .let's create a sustainable future.</p>
                            </div>
                        </div>
                    </div>
                    <div className="form-contact">
                        <form>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <i className="fas fa-user"></i>
                                        <input type="text" name="name" placeholder="ENTER YOUR NAME" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <i className="fas fa-envelope"></i>
                                        <input type="text" name="email" placeholder="ENTER YOUR EMAIL" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <i className="fas fa-phone"></i>
                                        <input type="text" name="phoneNumber" placeholder="ENTER YOUR PHONE NUMBER" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="single-input">
                                        <i className="fas fa-check"></i>
                                        <input type="text" name="subject" placeholder="ENTER YOUR SUBJECT" />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="single-input">
                                        <i className="fas fa-comment-dots"></i>
                                        <textarea placeholder="ENTER YOUR MESSAGE"></textarea>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="submit-input text-center mt-5">
                                        <input type="submit" name="submit" value="SUBMIT NOW" className="customBtn" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="whtsapp">
                            <a target="_blank"
                                href="https://api.whatsapp.com/send?phone=919350813134&amp;text=Hi%20Dr.%20Radhika" style={{ paddingTop: '0px', paddingLeft: ' 10px' }} className="whatsapp-button">
                                <i className="fa-brands fa-whatsapp"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>


           


        </>
    )
}
