import React from 'react';
import BannerImage from '../../Assets/Images/bg-11.jpg';

export const Banner = () => {
    return (
        <>
            <div id="myCarousel" className="carousel slide">
                <div className="carousel-inner">
                    <div className="item active" style={{ backgroundImage: `url(${BannerImage})` }}>
                        <div className="carouselcaption">
                            <h1 className="animated fadeInLeft">
                                <span style={{ color: '#00bf63' }}>Embracing <br />Sustainability <br /> for a  </span>
                                <span>Harmonious Future</span>
                            </h1>
                            <p className="animated fadeInUp">Educating for Mindful Consumption and Renewable Energy Adoption</p>
                            <p className="animated fadeInUp mt-5"><a href="#contact" className="customBtn">Enquir Now</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Banner;
